import { useMemo, useState, useEffect, useCallback, useContext } from 'react';
import { Modal } from 'components/Modal';
import { Trans, useTranslation } from 'react-i18next';
import classes from './selectEmailTemplate.module.scss';
import { SearchBar } from 'components/ListPage/components/SearchBar';
import { useForm } from 'react-final-form';
import { useSystemUserId } from 'lib/helpers';
import { Radio } from 'components/Radio';
import { Button } from 'components/Button';
import { Loader } from 'components/Loader';
import { ScreenContext } from 'providers/ScreenProvider';
import { NotificationType, useNotifications } from 'providers/NotificationsProvider';
import { HistoryLink } from 'components/HistoryLink';
import { routes } from 'domain/routes';
import singlePageClasses from 'components/SinglePage/singlePage.module.scss';
import cx from 'classnames';
import { useApi } from 'domain/api';

export const SelectEmailTemplate = () => {
  const [visible, setVisible] = useState(false);
  const hide = useCallback(() => setVisible(false), []);
  const { t } = useTranslation();
  const [search, setSearch] = useState('');
  const userId = useSystemUserId();
  const { isMobile } = useContext(ScreenContext);
  const { addNotification, addError } = useNotifications();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<Record<string, any>[]>([]);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  const { getUserTemplates } = useApi();

  const [selectedIndex, setSelectedIndex] = useState(-1);
  const {
    bahai_subject: subject,
    bahai_body: body,
    bahai_templateid: templateid,
    bahai_id: id,
    '_ownerid_value@OData.Community.Display.V1.FormattedValue': owneridValue,
    _ownerid_value: ownerId,
  } = useMemo(
    () =>
      data[selectedIndex] || {
        bahai_subject: null,
        bahai_body: null,
        bahai_templateid: null,
        bahai_id: null,
        '_ownerid_value@OData.Community.Display.V1.FormattedValue': null,
        _ownerid_value: null,
      },
    [data, selectedIndex]
  );

  const { change } = useForm();

  const dismiss = useCallback(() => {
    setSelectedTabIndex(0);
    setSelectedIndex(-1);
    setSearch(``);
    hide();
  }, [hide]);

  const apply = useCallback(() => {
    change(`subject`, subject);
    change(`description`, body);
    dismiss();
  }, [body, change, dismiss, subject]);

  const loadTemplates = useCallback(() => {
    setLoading(true);
    getUserTemplates(search.trim(), !!selectedTabIndex)
      .then(({ data: { value } }) => {
        if (value.length === 0 && search === '') {
          addNotification({
            title: t('Action cannot be completed'),
            type: NotificationType.INFO,
            content: userId ? (
              <Trans>
                You don`t have any templates in your view. Please, go to
                <HistoryLink to={routes.resource({ id: userId || 0 }) + '/template#create'} target="_blank">
                  {' '}
                  hyperlink
                </HistoryLink>{' '}
                to create a template.
              </Trans>
            ) : undefined,
          });
        } else {
          setData(value);
        }
      })
      .catch(() => {
        addError(t('Something Went Wrong'));
      })
      .finally(() => {
        setSelectedIndex(-1);
        setLoading(false);
      });
  }, [addError, addNotification, getUserTemplates, search, t, userId, selectedTabIndex]);

  useEffect(() => {
    if (!visible) {
      return;
    }
    loadTemplates();
    // eslint-disable-next-line
  }, [search, selectedTabIndex, visible]);

  const display = useCallback(() => {
    setVisible(true);
  }, [setVisible]);

  return (
    <>
      <div className={classes.buttonWrapper}>
        <button type="button" className={classes.emailTemplatesButton} onClick={display}>
          <Trans>Select Template</Trans>
        </button>
      </div>
      {visible && (
        <Modal
          title={t('Email Template')}
          onClose={dismiss}
          className={classes.modal}
          fixedSize={true}
          showZoom={true}
          isDraggingAllowed={false}
          collapsedLabel={t(`Email Template`)}
          noPadding
          controls={[
            () => (
              <div className={classes.footer}>
                <div className={classes.warning}>
                  <Trans>
                    After you select the email template, the current information in the fields will be overwritten.
                  </Trans>
                </div>
                <div className={classes.btn}>
                  <Button role="primary" disabled={selectedIndex < 0} onClick={apply}>
                    {t('Apply Template')}
                  </Button>
                  <Button role="secondary" onClick={dismiss}>
                    {t('Cancel')}
                  </Button>
                </div>
              </div>
            ),
          ]}
        >
          <div className={classes.content}>
            <div className={classes.leftPanel}>
              <SearchBar
                value={search}
                onChange={setSearch}
                fields={selectedTabIndex ? [`Title`, `IDN`, `Assignee`] : [`Title`, `IDN`]}
                className={classes.search}
              />
              <div className={singlePageClasses.tabBtnWrapper}>
                <button
                  className={cx(singlePageClasses.tabBtn, { [singlePageClasses.selected]: selectedTabIndex === 0 })}
                  onClick={() => setSelectedTabIndex(0)}
                >
                  {t('My Templates')}
                </button>
                <button
                  className={cx(singlePageClasses.tabBtn, { [singlePageClasses.selected]: selectedTabIndex === 1 })}
                  onClick={() => setSelectedTabIndex(1)}
                >
                  {t('All Templates')}
                </button>
              </div>
              <div className={classes.list}>
                {!loading &&
                  data.map((el: Record<string, any>, key: number) => (
                    <button
                      onClick={() => setSelectedIndex(key)}
                      className={cx(classes.element, { [classes.selected]: key === selectedIndex })}
                      key={key}
                    >
                      <Radio checked={key === selectedIndex} />
                      <div className={classes.cell}>{el.bahai_name}</div>
                    </button>
                  ))}
                {data.length === 0 && !loading && (
                  <div className={classes.nodata}>
                    <Trans>No data avialable</Trans>
                  </div>
                )}
              </div>
              {loading && (
                <div className={classes.loader}>
                  <Loader />
                </div>
              )}
            </div>
            {!isMobile && (
              <div className={classes.rightPanel}>
                {selectedIndex > -1 && (
                  <div className={classes.blockWrapper}>
                    <div className={classes.blockHeader}>
                      <div>
                        <div className={classes.label}>IDN</div>
                        <div className={classes.value}>
                          <HistoryLink to={routes.template({ id: templateid || 0 })} target="_blank">
                            {id}
                          </HistoryLink>
                        </div>
                      </div>
                      <div>
                        <div className={classes.label}>Assignee</div>
                        <div className={classes.value}>
                          <HistoryLink to={routes.resource({ id: ownerId || 0 })} target="_blank">
                            {owneridValue}
                          </HistoryLink>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className={classes.label}>Subject</div>
                      <div className={classes.subject}>{subject}</div>
                    </div>
                    <div className={classes.label}>Body</div>
                    <div
                      dangerouslySetInnerHTML={{ __html: body }}
                      className={cx(singlePageClasses.ckeditor, classes.body)}
                    ></div>
                  </div>
                )}
              </div>
            )}
          </div>
        </Modal>
      )}
    </>
  );
};
