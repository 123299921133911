import { useCallback, useEffect, useState, useContext, FC, useRef, ReactNode } from 'react';
import Joyride, { CallBackProps } from 'react-joyride';
import { useSteps, OnboardingStep } from './steps';
import { useHistory } from 'react-router-dom';
import { createContext } from 'react';
import { ScreenContext } from 'providers/ScreenProvider';
import { useTranslation } from 'react-i18next';
import { AuthContext } from 'providers/AuthProvider';
import { useRecord } from 'lib/record';
import { useSystemUserId } from 'lib/helpers';
import classes from './onboarding.module.scss';

export const OnboardingContext = createContext({} as { startTour: () => void });

const OnboardingTour: FC<{ children: ReactNode }> = ({ children }) => {
  const currentUserId = useSystemUserId();
  const { patch } = useRecord('resource');
  const [stepIndex, setStepIndex] = useState<number>(0);
  const stepIndexRef = useRef(stepIndex);
  const stepRef = useRef<OnboardingStep>();
  const [run, setRun] = useState(false);
  const steps = useSteps();
  const { push } = useHistory();
  const { t } = useTranslation();
  const {
    user: { username },
  } = useContext(AuthContext);

  useEffect(() => {
    stepIndexRef.current = stepIndex;
  }, [stepIndex]);

  const hideSpotLight = useCallback(() => {
    setStepIndex(0);
  }, [setStepIndex]);

  const setTutorialStartDateTime = useCallback(async () => {
    await patch({ bahai_tutorialstartdatetime: new Date().toISOString() }, currentUserId);
  }, [patch, currentUserId]);

  const windowClick = useCallback(
    (event: Event) => {
      if (stepRef.current?.disableWindowClicks) {
        const target = event.target as HTMLElement;
        const isTarget = !!target.closest(stepRef.current.target as string);
        const isTooltipElement = document.querySelector('.react-joyride__tooltip')?.contains(target);
        const isButton = !!target.closest('button');
        const denyClicks = document.querySelector<HTMLElement>(`#filterBySelect`);
        if (!denyClicks && !isTarget && !(isTooltipElement && isButton)) {
          event.stopImmediatePropagation();
        }
      }
    },
    [stepRef]
  );

  const closeTour = useCallback(() => {
    window.removeEventListener('click', windowClick, true);
  }, [windowClick]);

  const startTour = useCallback(() => {
    window.addEventListener('click', windowClick, true);
    push(`/`);
    setStepIndex(1);
    setTimeout(() => {
      setRun(true);
      setTutorialStartDateTime();
    }, 1000);
  }, [setRun, push, setTutorialStartDateTime, windowClick]);

  const { isMobile, isTablet } = useContext(ScreenContext);

  useEffect(() => {
    if (localStorage.getItem(`onboardingTourStarted_${username}`) !== 'true' && !(isMobile || isTablet)) {
      localStorage.setItem(`onboardingTourStarted_${username}`, 'true');
      setTimeout(() => {
        startTour();
      }, 1000);
    }
  }, [startTour, setStepIndex, setRun, isMobile, isTablet, username]);

  const handleCallback = useCallback(
    ({ action, index, type, step }: CallBackProps) => {
      if (index === 0) return;
      stepRef.current = step;
      const hasNexButton = !step.hideFooter;
      const { before } = steps[index + 1] || {};
      const { end } = steps[index] || {};
      const goForward = () => {
        if (typeof before !== 'undefined') {
          before(hideSpotLight).then((passSteps) => {
            typeof passSteps === 'number' ? setStepIndex(index + 1 + passSteps) : setStepIndex(index + 1);
          });
        } else {
          setStepIndex(index + 1);
        }
      };
      if (type === 'step:before') {
        if (!hasNexButton) {
          goForward();
        }
      }
      if (type === 'step:after') {
        if (hasNexButton) {
          goForward();
        }
      }
      switch (action) {
        case 'next':
          if (type === 'tour:end') {
            end && end();
            setRun(false);
            closeTour();
          }
          break;
        case 'close':
          if (stepRef.current?.removePointerEventsOnClose) {
            document.querySelectorAll<HTMLElement>('*').forEach((x) => (x.style.pointerEvents = ''));
          }
          end && end();
          setRun(false);
          closeTour();
          break;
      }
    },
    [steps, setStepIndex, hideSpotLight, closeTour]
  );

  return (
    <>
      <Joyride
        callback={handleCallback}
        continuous
        run={run}
        scrollToFirstStep
        showProgress
        stepIndex={stepIndex}
        steps={steps}
        disableScrolling={true}
        floaterProps={{ disableAnimation: true }}
        locale={{ close: '', next: t('Next') }}
        styles={{
          spotlight: {
            borderRadius: 16,
            position: 'absolute',
            animation: 'none',
          },
          tooltip: {
            backgroundColor: '#fff',
            borderRadius: 6,
            boxSizing: `content-box`,

            color: '#323130',
            fontSize: 18,
            padding: 15,
            position: 'relative',
            fontFamily: `Segoe UI`,
          },
          tooltipContainer: {
            textAlign: 'left',
            fontFamily: `Segoe UI`,
          },
          tooltipTitle: {
            fontSize: 18,
            margin: '0 0 10px 0',
          },
          tooltipContent: {
            padding: '16px 16px',
            color: '#323130',
            fontSize: 18,
            fontFamily: `Segoe UI, ui-serif`,
          },
          tooltipFooter: {
            alignItems: 'left',
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: 0,
          },
          buttonNext: {
            backgroundColor: '#fff',
            borderRadius: 6,
            color: '#0078D4',
          },
          options: {
            arrowColor: '#fff',
            backgroundColor: '#fff',
            primaryColor: '#313230',
            textColor: '#323130',
            overlayColor: 'rgba(0, 0, 0, 0.7)',
            spotlightShadow: '0 0 15px rgba(0, 255, 0, 0.9)',
            beaconSize: 36,
            zIndex: 100,
          },
        }}
      />
      <div className={classes.technicalElementForTour} id="technical_id_for_tour"></div>
      <OnboardingContext.Provider value={{ startTour }}>{children}</OnboardingContext.Provider>
    </>
  );
};

export default OnboardingTour;
