import axios from 'axios';
import * as Sentry from '@sentry/react';
import { ServerErrorType, useServerError } from 'providers/ErrorProvider';
import { baseURL } from 'components/AgreementInfo/hooks';
import convert from 'xml-js';

export const formatXmlQuery = (query: Record<string, any>) =>
  encodeURIComponent(
    convert.js2xml(query, {
      compact: true,
      attributeValueFn: (value) => {
        return value
          .replace(/&(?!quot;)/g, '&amp;')
          .replace(/</g, '&lt;')
          .replace(/</g, '&lt;')
          .replace(/'/g, '&apos;');
      },
    })
  );

export const createAxiosInstance = (addError: ReturnType<typeof useServerError>) => {
  const instance = axios.create({ baseURL });
  instance.interceptors.response.use(
    (resp) => resp,
    (error) => {
      if (process.env.NODE_ENV === 'production') {
        Sentry.captureException(error, {
          contexts: {
            response: error?.response,
            data: error?.response?.data,
            error: error?.response?.data?.error,
          },
        });
      }
      if (
        (error.response?.status === ServerErrorType.Errr401 ||
          error.response?.status === ServerErrorType.Errr403 ||
          error.response?.status === ServerErrorType.Errr404) &&
        !['post', 'patch', 'delete'].includes(error.response?.config?.method)
      ) {
        addError && addError(error.response?.status as ServerErrorType);
      }
      return Promise.reject(error);
    }
  );
  return instance;
};
