import { ChangeEventHandler, useCallback } from 'react';
import inputClasses from 'components/Form/Input/input.module.scss';
import { PHONE_OUTPUT_FORMAT, TValueComponent } from 'lib';
import MaskedInput from 'react-text-mask';

export const Input: TValueComponent<{ inputType?: 'text' | 'area' | 'phone'; className?: string }> = ({
  inputType = 'text',
  value = '',
  onChange,
  className = inputClasses.input,
  ...props
}) => {
  const onInputChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = useCallback(
    (e) => {
      onChange(e.target.value || '');
    },
    [onChange]
  );

  return (
    <>
      {inputType === 'area' && (
        <textarea className={className} rows={3} onChange={onInputChange} value={value} {...props} />
      )}
      {inputType === 'text' && <input className={className} onChange={onInputChange} value={value} {...props} />}
      {inputType === 'phone' && (
        <MaskedInput
          className={className}
          mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
          value={value}
          onChange={onInputChange}
          {...props}
          placeholder={PHONE_OUTPUT_FORMAT}
        />
      )}
    </>
  );
};
